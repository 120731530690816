<template>
  <el-dialog :model-value="props.visible" width="100%" modal-class="popup-intro" @close="handleClose()">
    <template #default>
      <div class="max-w-[765px] mx-auto flex flex-col items-center justify-center">
        <div class="relative">
          <img :src="mainImageSplash" class="aspect-square" :class="[ !viewPortPC ? 'w-full' : 'w-[20rem]']" />
          <button :disabled="!popup_splash.url" class="absolute bottom-[18%] left-[50%] -translate-x-[50%] w-[60%] h-[4rem]" @click="handleActionPopup()">
          </button>
        </div>
        <div :class="[ !viewPortPC ? 'w-full' : 'w-[20rem]']" class="flex control_popup justify-between px-4 py-2 rounded-b-[0.5rem] bg-[linear-gradient(39deg,#66E0D9_0%,#0054A1_100%)]">
          <el-checkbox v-model="stopAppearing" label="Don't show for 24 hours" />
          <button class="" @click="handleClose()">
            <img :src="iconsCommon.closeWhite" class="size-8" />
          </button>
        </div>
      </div>
    </template>
  </el-dialog>
</template>
<script setup lang="ts">
import { imgsPopupIntro } from '@/assets/images/define_images'
import { iconsCommon } from '@/assets/icons/define_icons'
import { SHOW_POPUP_SPLASH } from '~/constants/user'
import Store from '~/store/store'
const { viewPortPC } = storeToRefs(Store())
const emits = defineEmits(['close'])
const popup_splash = useRuntimeConfig().public.popup_splash

interface Props {
  visible: boolean
}
const props = withDefaults(defineProps<Props>(), {
  visible: true
})
const stopAppearing = ref(false)
const mainImageSplash = computed(() => {
  let img = imgsPopupIntro.welcome
  if(!popup_splash.url){
    return img = imgsPopupIntro.welcome
  }
  switch(popup_splash.keyword){
    case 'join_now':
      img = imgsPopupIntro.joinNow
      break
    default: 
      img = imgsPopupIntro.welcome
      break
  }
  return img
})
const handleSetLocalTimer = () => {
  const now = (new Date().getTime() / 1000).toFixed()
  window.localStorage.setItem(SHOW_POPUP_SPLASH, now.toString())
}
const handleClose = () => {
  if(stopAppearing.value){
    handleSetLocalTimer()
  }
  emits('close')
}
const handleActionPopup = () => {
  if(popup_splash.url){
    window.open(popup_splash.url)
  }
  emits('close')
}

</script>
<style lang="scss">
.popup-intro {
  @apply overflow-hidden;
  & > .el-overlay-dialog {
    @apply bg-[#0F4741] bg-opacity-20 backdrop-blur-lg overflow-hidden flex items-center justify-center;
    ::-webkit-scrollbar {
      width: 0;
    }
  }
  .el-dialog{
    @apply p-3 m-0 bg-transparent shadow-none;
    .el-dialog__headerbtn {
      @apply hidden;
    }
    .el-dialog__header.show-close{
      @apply p-0;
    }
    .el-dialog {
      @apply p-0;
    }
  }
  .control_popup{
    .el-checkbox__input{
      @apply border-transparent;
      .el-checkbox__inner{
        @apply size-5 bg-transparent border-white-0 border-[2px];
        &::after{
          @apply size-2;
        }
      }
    }
    .is-checked{
      .el-checkbox__inner{
        @apply bg-green-1 #{!important};
        &::after{
          @apply border-[2px] left-[5px] w-1;
        }
      }
    }
    .el-checkbox__label{
      @apply text-white-0 font-semibold;
    }
  }
}
</style>
